/* You can add global styles to this file, and also import other style files */

/*html, body { height: 100%; }
//body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
*/

/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

/* Webfont: Lato-Black */
@font-face {
    font-family: 'LatoBlack';
    src: url('./assets/fonts/Lato-Black.eot');
    /* IE9 Compat Modes */
    src: url('./assets/fonts/Lato-Black.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('./assets/fonts/Lato-Black.woff2') format('woff2'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-Black.woff') format('woff'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-Black.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: Lato-BlackItalic */
@font-face {
    font-family: 'LatoBlack';
    src: url('./assets/fonts/Lato-BlackItalic.eot');
    /* IE9 Compat Modes */
    src: url('./assets/fonts/Lato-BlackItalic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('./assets/fonts/Lato-BlackItalic.woff2') format('woff2'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-BlackItalic.woff') format('woff'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-BlackItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Bold */
@font-face {
    font-family: 'LatoBold';
    src: url('./assets/fonts/Lato-Bold.eot');
    /* IE9 Compat Modes */
    src: url('./assets/fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('./assets/fonts/Lato-Bold.woff2') format('woff2'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-Bold.woff') format('woff'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: Lato-BoldItalic */
@font-face {
    font-family: 'LatoBold';
    src: url('./assets/fonts/Lato-BoldItalic.eot');
    /* IE9 Compat Modes */
    src: url('./assets/fonts/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('./assets/fonts/Lato-BoldItalic.woff2') format('woff2'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-BoldItalic.woff') format('woff'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-BoldItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Hairline */
@font-face {
    font-family: 'LatoHairline';
    src: url('./assets/fonts/Lato-Hairline.eot');
    /* IE9 Compat Modes */
    src: url('./assets/fonts/Lato-Hairline.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('./assets/fonts/Lato-Hairline.woff2') format('woff2'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-Hairline.woff') format('woff'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-Hairline.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: Lato-HairlineItalic */
@font-face {
    font-family: 'LatoHairline';
    src: url('./assets/fonts/Lato-HairlineItalic.eot');
    /* IE9 Compat Modes */
    src: url('./assets/fonts/Lato-HairlineItalic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('./assets/fonts/Lato-HairlineItalic.woff2') format('woff2'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-HairlineItalic.woff') format('woff'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-HairlineItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Heavy */
@font-face {
    font-family: 'LatoHeavy';
    src: url('./assets/fonts/Lato-Heavy.eot');
    /* IE9 Compat Modes */
    src: url('./assets/fonts/Lato-Heavy.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('./assets/fonts/Lato-Heavy.woff2') format('woff2'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-Heavy.woff') format('woff'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-Heavy.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: Lato-HeavyItalic */
@font-face {
    font-family: 'LatoHeavy';
    src: url('./assets/fonts/Lato-HeavyItalic.eot');
    /* IE9 Compat Modes */
    src: url('./assets/fonts/Lato-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('./assets/fonts/Lato-HeavyItalic.woff2') format('woff2'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-HeavyItalic.woff') format('woff'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-HeavyItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Italic */
@font-face {
    font-family: 'Lato';
    src: url('./assets/fonts/Lato-Italic.eot');
    /* IE9 Compat Modes */
    src: url('./assets/fonts/Lato-Italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('./assets/fonts/Lato-Italic.woff2') format('woff2'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-Italic.woff') format('woff'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Light */
@font-face {
    font-family: 'LatoLight';
    src: url('./assets/fonts/Lato-Light.eot');
    /* IE9 Compat Modes */
    src: url('./assets/fonts/Lato-Light.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('./assets/fonts/Lato-Light.woff2') format('woff2'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-Light.woff') format('woff'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-Light.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: Lato-LightItalic */
@font-face {
    font-family: 'LatoLight';
    src: url('./assets/fonts/Lato-LightItalic.eot');
    /* IE9 Compat Modes */
    src: url('./assets/fonts/Lato-LightItalic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('./assets/fonts/Lato-LightItalic.woff2') format('woff2'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-LightItalic.woff') format('woff'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-LightItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Medium */
@font-face {
    font-family: 'LatoMedium';
    src: url('./assets/fonts/Lato-Medium.eot');
    /* IE9 Compat Modes */
    src: url('./assets/fonts/Lato-Medium.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('./assets/fonts/Lato-Medium.woff2') format('woff2'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-Medium.woff') format('woff'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: Lato-MediumItalic */
@font-face {
    font-family: 'LatoMedium';
    src: url('./assets/fonts/Lato-MediumItalic.eot');
    /* IE9 Compat Modes */
    src: url('./assets/fonts/Lato-MediumItalic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('./assets/fonts/Lato-MediumItalic.woff2') format('woff2'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-MediumItalic.woff') format('woff'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-MediumItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Regular */
@font-face {
    font-family: 'Lato';
    src: url('./assets/fonts/Lato-Regular.eot');
    /* IE9 Compat Modes */
    src: url('./assets/fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('./assets/fonts/Lato-Regular.woff2') format('woff2'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-Regular.woff') format('woff'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Semibold */
@font-face {
    font-family: 'LatoSemibold';
    src: url('./assets/fonts/Lato-Semibold.eot');
    /* IE9 Compat Modes */
    src: url('./assets/fonts/Lato-Semibold.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('./assets/fonts/Lato-Semibold.woff2') format('woff2'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-Semibold.woff') format('woff'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-Semibold.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: Lato-SemiboldItalic */
@font-face {
    font-family: 'LatoSemibold';
    src: url('./assets/fonts/Lato-SemiboldItalic.eot');
    /* IE9 Compat Modes */
    src: url('./assets/fonts/Lato-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('./assets/fonts/Lato-SemiboldItalic.woff2') format('woff2'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-SemiboldItalic.woff') format('woff'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-SemiboldItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Thin */
@font-face {
    font-family: 'LatoThin';
    src: url('./assets/fonts/Lato-Thin.eot');
    /* IE9 Compat Modes */
    src: url('./assets/fonts/Lato-Thin.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('./assets/fonts/Lato-Thin.woff2') format('woff2'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-Thin.woff') format('woff'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-Thin.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: Lato-ThinItalic */
@font-face {
    font-family: 'LatoThin';
    src: url('./assets/fonts/Lato-ThinItalic.eot');
    /* IE9 Compat Modes */
    src: url('./assets/fonts/Lato-ThinItalic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('./assets/fonts/Lato-ThinItalic.woff2') format('woff2'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-ThinItalic.woff') format('woff'),
        /* Modern Browsers */
        url('./assets/fonts/Lato-ThinItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}


* {
    font-family: Lato, Arial, Helvetica, sans-serif;
}


html,
body {
    margin: 0;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
}

h1 {
    word-break: break-all;
}

p{
    margin:0;
}

.h1Extended {
    width: 237px;
    height: 81px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;
    /* or 119% */
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}

.h2Extended {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 25px;
    text-align: center;
    letter-spacing: -0.333333px;
    margin-bottom: 16px;
    color: #000000;
}

.h2ExtendedCVV {
    width: 293px;
    height: 56px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 25px;
    text-align: center;
    letter-spacing: -0.333333px;
    color: #000000;
}


.mat-mdc-card:not([class*=mat-elevation-z]) {
    box-shadow: none !important;
}

input.mat-input-element {
    background: white
}

.mat-input-element {
    caret-color: #1D87FC !important;
}

.mat-mdc-card-header-text {
    width: 100% !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 2px;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: #1D87FC !important;
}

.mat-form-field.mat-focused .mat-form-field-underline {
    background-color: #1D87FC !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
    color: rgba(0, 0, 0, .54);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: #1D87FC !important;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
    color: rgba(0, 0, 0, .54);
}

.mat-form-field {
    font-size: inherit;
    font-weight: 400;
    line-height: 2;
    font-family: Lato;
    width: 100%;
}

.btnPrimary {
    width: 241px;
    height: 47px;
    background: #1D87FC;
    border-radius: 13px;
    color: #FFFFFF;
}

.btnPrimaryDisabled {
    border-radius: 6px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.333333px;
    width: 196px;
    height: 37px;
    border: none;
}


.p {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}

.amount {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 34px;
    text-align: center;
    letter-spacing: -0.333333px;

    color: #1D87FC;
}


.mat-toolbar.mat-primary {
    background: #ffffff;
    color: #000000;
    text-align: center;
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
    background-color: #1D87FC !important;
    width: 241px;

}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: #000000;
    animation: spinner .8s linear infinite;
}

.fill-remaining-space {
    /* This fills the remaining space, by using flexbox. 
       Every toolbar row uses a flexbox row layout. */
    word-break: break-word;
    margin-left: 30px;
    margin-right: 30px;
    text-align: center;
    width: 70%;

}

.confirm-label {
    width: 222px;
    height: 56px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: -0.333333px;
    color: #000000;
}

.content_home {
    width: 318px;
    height: 369px;
    background: #41C5F9;
    box-shadow: 0px 1px 3px #FFFFFF;
    border-radius: 27px;
}
.content_home_noRound {
    width: 318px;
    height: 450px;
    background-color: #FFFFFF !important;
    box-shadow: 0px 1px 3px #FFFFFF;
}

.subtitle {
    height: 81px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 31px;
    text-align: center;
    color: #FFFFFF;
}


.mat-divider {
    display: block;
    margin: 0;
    border-top-width: 2px;
    border-top-style: solid;
    border-block-color: #1D87FC;
}

.mat-progress-spinner.mat-accent circle,
.mat-spinner.mat-accent circle {
    stroke: #FFFFFF;
}

.responsive {
    width: 100%;
    height: auto;
}

.form {
    min-width: 150px;
    max-width: 300px;
}

.form-element {
    padding: 5px 0px 25px 2px;
    width: 90%;
}

.footer-fixed {
    bottom: 0;
    position: fixed;
    align-items: center;
    display: flex;
    width: 100%;
}

.mat-mdc-card{
    box-shadow:none;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing{
    border-color: #1D87FC
}

.mat-mdc-raised-button:not(:disabled) {
    background-color:  #1D87FC !important;
}